import React from 'react';
import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

//Assets
import slide_item_one from '../../../../../asset/resource/slide_item_one.png';
import asset_management from '../../../../../asset/resource/asset-management.png';
import onboarding from '../../../../../asset/resource/onboarding.png';
import customer_service from '../../../../../asset/resource/customer-service.png';

import { useCheckMobile } from '../../../../hooks';

import './UseCases.scss';

const UseCases = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="use-cases" className="use-cases">
      <div className="use-cases__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <div className="sub-title-wrapper">
            <span className="sub-title">AREAS OF EXPERTISE</span>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
        >
          <h2>
            HOW CAN <span>GEN</span> HELP YOU?
          </h2>
        </motion.div>
        <div className="use-cases__slider-wrapper">
          <Carousel
            showArrows
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            swipeScrollTolerance={20}
            preventMovementUntilSwipeScrollTolerance={40}
          >
            <div className="use-cases__slide">
              <article>
                <h3>CUSTOMER SUPPORT</h3>
                <p>
                  Gen makes customer access to critical information fast and
                  easy. Your customers no longer need to wait to reach a
                  customer support operator or type out questions. No more
                  irrelevant information from a limited response library. Gen is
                  available 24/7 and understands spoken language. Customers can
                  simply ask questions and receive a precise answer.
                  <br />
                  <br />
                  <strong>Gen helps you:</strong>
                  <br />
                  <br />
                  <ul>
                    <li>Serve hundreds of customers simultaneously</li>
                    <li>Offer customers assistance in multiple languages </li>
                    <li>Build an emotional bond with your customers</li>
                  </ul>
                </p>
              </article>
              <figure className="use-cases__image">
                <img
                  width="100%"
                  src={asset_management}
                  className="asset_management-image"
                  alt="asset management image"
                />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>COMPANY EDUCATION</h3>
                <p>
                  Gen offers in-depth insights about your company, empowering
                  customers to make optimal decisions. Integrate the latest CAD
                  descriptions from the engineering team or brand materials from
                  marketing. When Gen has the right information, she tailors
                  guidelines for each customer’s needs and fosters collaboration
                  across departments.
                  <br />
                  <br />
                  <strong>Gen helps you:</strong>
                  <br />
                  <br />
                  <ul>
                    <li>
                      Highlight your company's dedication to customer success
                    </li>
                    <li>
                      Enhance efficiency by streamlining document retrieval
                    </li>
                    <li>
                      Simplify internal operations and bolster overall
                      productivity
                    </li>
                  </ul>
                </p>
              </article>
              <figure className="use-cases__image">
                <img width="100%" src={slide_item_one} alt="" />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>Customer Onboarding</h3>
                <p>
                  Provide a personal touch for new customers throughout the
                  onboarding process. Gen greets them with an initial welcome
                  message, explains your products or services in-depth, and
                  ultimately leads them through the process of setting up their
                  accounts.
                  <br />
                  <br />
                  <strong>Gen can also: </strong>
                  <br />
                  <br />
                  <ul>
                    <li>Provide ongoing support after onboarding</li>
                    <li>Assist customers with any further issues</li>
                    <li>Offer updates on new products or services</li>
                  </ul>
                </p>
              </article>
              <figure className="use-cases__image">
                <img
                  width="100%"
                  src={onboarding}
                  className="onboarding-image"
                  alt="onboarding image"
                />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>PRODUCT AND SERVICES EDUCATION </h3>
                <p>
                  Gen provides support to help your clients gain a better
                  understanding of any of your company’s products or services,
                  increasing their awareness about your company's offerings, and
                  reducing confusion or uncertainty.
                  <br />
                  <br />
                  <strong>Gen enables you to:</strong>
                  <br />
                  <br />
                  <ul>
                    <li>
                      Show that customer success is one of your top priorities
                    </li>
                    <li>
                      Support customers with consistently up-to-date information
                    </li>
                    <li>
                      Help customers to make informed decisions about products
                      and services
                    </li>
                  </ul>
                </p>
              </article>
              <figure className="use-cases__image">
                <img
                  width="100%"
                  src={customer_service}
                  className="customer_service-image"
                  alt="Customer Service image"
                />
              </figure>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
