import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';

import money_2020_webm from '../../../../../asset/resource/veda.webm';
import money_2020 from '../../../../../asset/resource/gen.mov';

import { useCheckMobile } from '../../../../hooks';

import './Title.scss';

export const Title = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [isStart1, setIsStart1] = useState(false);

  const handleView = (inView) => {
    if (inView) {
      const video = document.getElementById('box-video');
      setTimeout(function () {
        video.play();
      }, 2000);
    }
  };

  useEffect(() => {
    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) setIsSafariBrowser(true);
  }, [isSafariBrowser]);

  return (
    <section className={cn('main-title', isSafariBrowser && 'safari')}>
      <div className="main-title__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <h1>
            Who is
            <br />
            softserve
            <span> gen?</span>
          </h1>
        </motion.div>
        <div className="main-title__heading">
          <div className="main-title__heading-content">
            <p className={cn('main-title__description', isStart && 'visible')}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                onAnimationComplete={() => setIsStart(true)}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
              >
                Meet SoftServe Gen, a virtual customer assistant and digital
                concierge. Powered by Generative AI. Gen mimics human
                interactions, making the customer service experience more
                engaging and efficient.
                <br />
                <br />
              </motion.div>
            </p>
            <p className={cn('main-title__description', isStart1 && 'visible')}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                onAnimationComplete={() => setIsStart1(true)}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.8 }}
              >
                Developed using the SoftServe Accelerator Generative AI Digital
                Expert Ecosystem (GAIDEX) and based on NVIDIA ACE, Gen provides
                personalized and immersive interactions by answering questions
                customers have about your company.
                <br />
                <br />
                Gen can assist customers online, at trade shows, and your
                physical locations. No matter where she is, Gen is the ultimate
                point of contact.
                <br />
                <br />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.9 }}
              >
                <ScrollLink
                  smooth
                  spy
                  className="main-title__cta"
                  to="contact-us"
                >
                  Book your immersive experience
                </ScrollLink>
              </motion.div>
            </p>
          </div>
          <div className={cn('main-title__inview')}>
            <InView triggerOnce onChange={handleView} threshold={0}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                onAnimationComplete={() => setIsStart(true)}
                variants={
                  isMobile
                    ? {
                        visible: { x: 0, y: 0, opacity: 1 },
                      }
                    : {
                        visible: {
                          x: 0,
                          y: 0,
                          opacity: 1,
                        },
                        hidden: {
                          x: 300,
                          y: 200,
                          opacity: 0,
                        },
                      }
                }
                transition={{ type: 'anticipate', duration: 3.8, delay: 0.3 }}
              >
                <video
                  id="box-video"
                  className={cn(
                    'main-title__video-content',
                    isSafariBrowser && 'main-title__video-content_with-bg',
                  )}
                  width="100%"
                  loop
                  muted
                  playsInline
                >
                  {isSafariBrowser ? (
                    <source src={money_2020} type="video/mp4" />
                  ) : (
                    <source src={money_2020_webm} type="video/webm" />
                  )}
                  Your browser is not supported!
                </video>
              </motion.div>
            </InView>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Title;
