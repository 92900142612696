import Main from './pages/Main';
import NoMatch from './pages/NoMatch/NoMatch';

const routes = [
  {
    path: '/',
    component: Main,
    exact: true,
  },
  {
    path: '*',
    component: NoMatch,
    exact: true,
  },
];

export default routes;
