import { useEffect, useCallback, useState } from 'react';
import debounce from 'lodash.debounce';

const checkMobileDevice = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function () {
    return (
      checkMobileDevice.Android() ||
      checkMobileDevice.BlackBerry() ||
      checkMobileDevice.iOS() ||
      checkMobileDevice.Opera() ||
      checkMobileDevice.Windows()
    );
  },
};

export const useCheckMobile = ({ width, height, byDevices = false }) => {
  const checkWidth = useCallback(
    () => (width ? window.innerWidth <= width : true),
    [width],
  );
  const checkHeight = useCallback(
    () => (height ? window.innerHeight <= height : true),
    [height],
  );
  const checkForDevice = () =>
    byDevices && !!!width
      ? typeof navigator !== 'undefined' && checkMobileDevice.any()
      : typeof window !== 'undefined' && checkWidth() && checkHeight();

  const [isMobile, setIsMobile] = useState(
    byDevices && !!width
      ? (typeof navigator !== 'undefined' && checkMobileDevice.any()) ||
          (typeof window !== 'undefined' && window.innerWidth <= width)
      : checkForDevice(),
  );

  const resizeHandler = debounce(() => {
    const checkForDevice =
      byDevices && !!!width
        ? checkMobileDevice.any()
        : typeof window !== 'undefined' && checkWidth() && checkHeight();
    return setIsMobile(
      byDevices && !!width
        ? checkMobileDevice.any() ||
            (typeof window !== 'undefined' && window.innerWidth <= width)
        : checkForDevice,
    );
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  return !!isMobile;
};
