import React from 'react';
import { motion } from 'framer-motion';

//Assets
import block_one_image from '../../../../../asset/resource/block_one_image.png';
import block_two_image from '../../../../../asset/resource/block_two_image.png';
import block_three_image from '../../../../../asset/resource/block_three_image.png';

import { useCheckMobile } from '../../../../hooks';

import './HowItWorks.scss';

export const HowItWorks = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="how-it-works" className="how-it-works">
      <div className="how-it-works__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <span className="sub-title">How It Works</span>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
        >
          <h2>
            What makes <span>gen work?</span>
          </h2>
        </motion.div>
        <div className="how-it-works__blocks">
          <div className="how-it-works__block one">
            <article>
              <h3>Front end – digital avatar</h3>
              <p>
                SoftServe Gen is built using the NVIDIA Avatar Cloud Engine
                (ACE) and NVIDIA<sup>®️</sup> Riva, along with an Audio2Face
                component. Her lifelike representation captures both speech and
                facial expressions with the Character Creator tool to final
                integration, every detail of Gen’s appearance is designed to
                facilitate a seamless and engaging interaction.
              </p>
            </article>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: 200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1.2, delay: 1 }}
            >
              <figure className="how-it-works__image">
                <img width="100%" src={block_one_image} alt="" />
              </figure>
            </motion.div>
          </div>

          <div className="how-it-works__block two">
            <article>
              <h3>Core Brain – LLMs and Gen AI</h3>
              <p>
                Gen’s extraordinary conversational capabilities are powered by
                large language models (LLMs) and Generative AI (Gen AI)
                technology. That allows her to recognize requests, understand
                contexts, and answer questions in the most appropriate manner
                for that moment.
                <br />
                <br />
                Gen's flexibility gives her the ability to handle complex
                requests by simplifying answers. She can also offer detailed
                explanations.
              </p>
            </article>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: -200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1.2 }}
            >
              <figure className="how-it-works__image">
                <img src={block_two_image} alt="" />
              </figure>
            </motion.div>
          </div>

          <div className="how-it-works__block one">
            <article>
              <h3>Back end – internal integration</h3>
              <p>
                Gen integrates with existing systems and databases. That
                supports conversations by enriching detail and enhancing
                contextualization. This enables Gen to provide more relevant
                answers, supported by historical and personal data.
                <br />
                <br />
                The more data you provide, the more personalized the information
                will be that is presented to your customer.
              </p>
            </article>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: -200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1.2 }}
            >
              <figure className="how-it-works__image">
                <img src={block_three_image} alt="" />
              </figure>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
