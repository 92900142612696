import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';
import { motion } from 'framer-motion';

//Assets
import icon_1 from '../../../../../asset/resource/icon-1.svg';
import icon_2 from '../../../../../asset/resource/icon-2.svg';
import icon_3 from '../../../../../asset/resource/icon-3.svg';

import { useCheckMobile } from '../../../../hooks';

import './HowToUse.scss';

export const HowToUse = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const [isVisible, setIsVisible] = useState([]);

  const handleViewBlocks = (inView, i) => {
    if (inView) {
      setIsVisible((prevArray) => [...prevArray, (isVisible[i] = true)]);
    }
  };

  return (
    <section id="how-to-use" className="how-to-use">
      <div className="how-to-use__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <span className="sub-title">HOW TO USE </span>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
        >
          <h2>EXPERIENCE A NEW LEVEL OF CUSTOMER INTERACTION</h2>
        </motion.div>
        <div className="how-to-use__items-block">
          <InView
            triggerOnce
            onChange={(inView) => handleViewBlocks(inView, 0)}
            threshold={1}
          >
            <h3>
              Chat with Gen and experience a SoftServe solution for your
              customer service
            </h3>
          </InView>
          <div className="how-to-use__item">
            <div
              className={cn(
                'how-to-use__item-icons',
                isVisible[0] && 'visible',
              )}
            >
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.3 }}
              >
                <img src={icon_1} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
              >
                <img src={icon_3} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.7 }}
              >
                <img src={icon_2} alt="icon image" />
              </motion.div>
            </div>
            <div className="how-to-use__item-text">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
              >
                <p>
                  Gen focuses on business technology solutions. She'll share how
                  SoftServe can help you. Start by chatting with her about your
                  challenges. Ask her about technology trends. Speak at your own
                  pace — Gen will hear you.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.7 }}
              >
                <p>
                  Gen answers the questions you have about SoftServe and our
                  service offerings. She can also tell you about our partnership
                  with NVIDIA. And don’t forget to ask about our demos — she’s
                  more than happy to talk to you about them, too!
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.9 }}
              >
                <p>
                  Gen's design ensures relevant, personalized responses. Your
                  conversation about SoftServe offers insight into how Gen will
                  support your company. Gen is your powerful new solution for
                  transforming customer interaction.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToUse;
