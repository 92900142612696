import { isEmpty } from 'lodash';

export default (values) => {
  let errors = {};

  if (isEmpty(values.firstname)) {
    errors.firstName = true;
  }

  if (isEmpty(values.lastname)) {
    errors.lastName = true;
  }

  if (isEmpty(values.email)) {
    errors.email = true;
  }

  if (isEmpty(values.company)) {
    errors.company = true;
  }

  if (isEmpty(values.jobtitle)) {
    errors.job = true;
  }

  if (
    isEmpty(values.firstname) ||
    isEmpty(values.lastname) ||
    isEmpty(values.email) ||
    isEmpty(values.company) ||
    isEmpty(values.jobtitle)
  ) {
    errors.fillOutFields = 'Please fill out all the fields.';
  }

  if (!isEmpty(values.message) && values.message.length > 1999) {
    errors.message =
      'You really have a gift for words! Unfortunately, the limit for requests is 2,000 characters. Please rephrase as clearly and succinctly as possible. Thanks!';
  }

  if (
    !isEmpty(values.email) &&
    !/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
      values.email,
    )
  ) {
    errors.email = 'Please provide correct email.';
  }

  if (isEmpty(values.captcha)) {
    errors.captcha = 'Please fill out captcha.';
  }

  return errors;
};
